export function buildVehicle ({
    id,
    number_plate: numberPlate,
    dgt_environmental_label_number: dgtEnvironmetalLabelNumber,
    auto_open_permission: autoOpenPermission,
    fuel_type: fuelType,
    body_type: bodyType,
    use_type_slug: useType,
    alias,
}) {
    return {
        id,
        numberPlate,
        dgtEnvironmentalLabelNumber: dgtEnvironmetalLabelNumber,
        autoOpenPermission,
        fuelType,
        bodyType,
        useType,
        alias,
    }
}

export function buildBasicVehicle ({ id, alias, use_type_slug: useType, number_plate: numberPlate }) {
    return {
        id: id,
        numberPlate,
        useType,
        alias,
    }
}

export function mapEditVehicle ({
    id,
    modelId,
    fuelType,
    vehicleType,
    useType,
    alias,
}) {
    return ({
        id: id,
        id_vehicle_model: modelId,
        fuel_type: fuelType,
        body_type: vehicleType,
        use_type_slug: useType,
        alias,
    })
}

export function mapCreateVehicle ({
    numberPlate,
    modelId,
    autoOpenPermission,
    fuelType,
    dgtEnvironmetalLabelNumber,
    vehicleType,
    useType,
    alias,
}) {
    return {
        number_plate: numberPlate,
        dgt_environmental_label_number: dgtEnvironmetalLabelNumber,
        auto_open_permission: autoOpenPermission,
        id_vehicle_model: modelId,
        fuel_type: fuelType,
        body_type: vehicleType,
        use_type_slug: useType,
        alias,
    }
}

export function buildVehicles (data) {
    return data.items.map(buildVehicle)
}
