const buildInvoice = (invoice) => {
    return ({
        uid: invoice.uid,
        number: invoice.number,
    })
}

export function buildResult (order) {
    return ({
        id: order.id,
        hash: encodeURIComponent(order.id_hash),
        status: order.status,
        brand: order.pagateliaOrderLines[0].viatProduct && order.pagateliaOrderLines[0].viatProduct.brand,
        email: order.user && order.user.email,
        shippingData: {
            firstName: order.shipment_first_name,
            lastName: order.shipment_last_name,
            address: order.shipment_street,
            town: order.shipment_locality,
            postalCode: order.shipment_postalcode,
            phone: order.shipment_phone && order.shipment_phone.replaceAll(/\s+/g, ''),
            province: order.shipmentProvince,
            provinceId: order.shipment_province_id,
            nif: order.shipment_cif,
            businessName: order.shipment_company_name,
        },
        invoiceData: {
            firstName: order.invoice_name,
            lastName: order.invoice_last_name,
            address: order.invoice_street,
            town: order.invoice_locality,
            postalCode: order.invoice_postalcode,
            province: order.invoiceProvince,
            provinceId: order.invoice_province_id,
            nif: order.invoice_cif,
            businessName: order.invoice_company_name,
        },
        item: order.pagateliaOrderLines && {
            name: order.pagateliaOrderLines[0].name,
            units: order.pagateliaOrderLines[0].units,
            currency: order.pagateliaOrderLines[0].viatProduct && order.pagateliaOrderLines[0].viatProduct.currency,
        },
        wallet: order.wallet && {
            id: order.wallet.id,
            name: order.wallet.name,
        },
        creditCard: order.creditCard && {
            id: order.creditCard.id,
            lastNumbers: order.creditCard.last_numbers,
            brand: order.creditCard.brand,
            expirationMonth: order.creditCard.exp_month,
            expirationYear: order.creditCard.exp_year,
        },
        priceQty: order.total_qty,
        taxQty: order.tax_qty,
        totalShippingBasePrice: order.total_shipping_qty,
        quotaQty: order.quota_qty,
        promoCode: order.promotional_code_discounted_qty && {
            qty: order.promotional_code_discounted_qty,
            name: order.promotional_code,
            code: order.promotional_code,
            id: order.id_promotional_code,
        },
        trackingUrl: order.tracking_url,
        units: order.units,
        invoices: order.invoices.map(buildInvoice),
        walletId: order.id_wallet,
        price: order.priceDetails && {
            promoCodeDiscountedPrice: order.priceDetails.promotional_code_discounted_price,
            singleProductQuotaPrice: order.priceDetails.quota_base_price,
            totalProductBasePrice: order.priceDetails.total_base_price,
            totalQuotaBasePrice: order.priceDetails.total_quota_base_price,
            taxQty: order.priceDetails.total_tax_price,
            totalShippingBasePrice: order.priceDetails.total_shipping_base_price,
            totalPriceQty: order.priceDetails.total_price_with_tax,
        },
    })
}
