export function buildMobe (mobe) {
    return {
        id: mobe.id,
        pan: mobe.pan,
        vehicle: mobe.vehicle ? {
            id: mobe.vehicle.id,
            numberPlate: mobe.vehicle.number_plate,
        } : null,
        wallet: mobe.wallet ? {
            id: mobe.wallet.id,
            name: mobe.wallet.name,
        } : null,
        endQuota: mobe.date_end_quota,
        expiration: mobe.deleted_at,
        startQuota: mobe.date_start_quota,
        isActive: mobe.isActive,
        status: mobe.status,
    }
}
