import Routes from '../routes/routes'
import { buildPutApi } from './helpers'
import { buildResult } from './converters/viatOrderData'

function mapParams (params) {
    return {
        idHash: params.hash,
    }
}

export const trimNif = (nif) => nif && nif.replaceAll(/\s+/g, '')
export function mapPayload (order) {
    const { shippingData, invoiceData, wallet, item } = order

    return ({
        shipment_first_name: shippingData.firstName,
        shipment_last_name: shippingData.lastName,
        shipment_street: shippingData.address,
        shipment_locality: shippingData.town,
        shipment_postalcode: shippingData.postalCode,
        shipment_phone: shippingData.phone && shippingData.phone.replaceAll(/\s+/g, ''),
        shipment_province: shippingData.province,
        shipment_province_id: shippingData.provinceId,
        shipment_cif: trimNif(shippingData.nif),
        shipment_company_name: shippingData.businessName,
        invoice_name: invoiceData.firstName,
        invoice_last_name: invoiceData.lastName,
        invoice_street: invoiceData.address,
        invoice_locality: invoiceData.town,
        invoice_postalcode: invoiceData.postalCode,
        invoice_province: invoiceData.province,
        invoice_province_id: invoiceData.provinceId,
        invoice_cif: trimNif(invoiceData.nif),
        invoice_company_name: invoiceData.businessName,
        units: item.units,
        id_wallet: wallet && wallet.id,
    })
}

export default buildPutApi({
    route: Routes.apiUpdatePagateliaOrder,
    buildResult,
    mapParams,
    mapPayload,
})
