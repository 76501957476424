import { parseMoment } from 'commons/js/util/dateConverter'
import moment from 'moment'
import { AlreadyBookedError, CreditCardInvalidError, NumberPlateNotInWhiteListError } from '../errors'
import { ALREADY_BOOKED_ERROR, CREDIT_CARD_DECLINED_ERROR, NUMBER_PLATE_NOT_IN_WHITELIST } from '../constants'
import { buildBookingErrorData } from './error'

export function buildVehicleInspectionBooking (booking) {
    const bookingDate = moment(booking.date * 1000)
    const expired = booking.date
        ? (bookingDate.diff(parseMoment()) < 0)
        : false
    return {
        id: booking.id,
        startDate: booking.date,
        expired,
        code: booking.code,
        cancellationLimitDate: booking.expiration_date,
        priceQty: booking.final_price,
        station: {
            id: booking.vehicleInspectionStation.id,
            slug: booking.vehicleInspectionStation.slug,
            name: booking.vehicleInspectionStation.name,
            description: booking.vehicleInspectionStation.description,
            address: booking.vehicleInspectionStation.address,
            email: booking.vehicleInspectionStation.email,
            phone: booking.vehicleInspectionStation.phone,
            timetable: booking.vehicleInspectionStation.timetable,
            timeZone: booking.vehicleInspectionStation.time_zone_name,
        },
        vehicle: {
            numberPlate: booking.vehicle.number_plate,
            vehicleType: booking.vehicle.body_type,
            fuelType: booking.vehicle.fuel_type,
        },
        productPrice: booking.vehicleInspectionStationProduct.online_price_without_commission,
        priceWithoutCommission: booking.price_with_no_commission,
        userCommissionPrice: booking.userCommission.qty,
        productName: booking.vehicleInspectionStationProduct.name,
        isPostPayment: booking.vehicleInspectionStationProduct.is_post_payment,
        requiresAppointment: booking.vehicleInspectionStationProduct.requires_appointment,
        canceled: booking.cancelled,
        isCancelable: booking.isCancelable,
    }
}

export function buildItvBookingError (error) {
    const errorData = buildBookingErrorData(error)
    if (error instanceof AlreadyBookedError) {
        return {
            name: ALREADY_BOOKED_ERROR,
            status: 457,
            data: errorData,
        }
    } else if (error instanceof NumberPlateNotInWhiteListError) {
        return {
            name: NUMBER_PLATE_NOT_IN_WHITELIST,
            status: 445,
            data: errorData,
        }
    } else if (error instanceof CreditCardInvalidError) {
        return {
            name: CREDIT_CARD_DECLINED_ERROR,
            status: 474,
            data: errorData,
        }
    }
    return errorData
}
