
import {
    UNAUTHORIZED_ERROR,
    FORBIDDEN_ERROR,
    NOT_FOUND_ERROR,
    UNPROCESSABLE_ENTITY_ERROR,
    SERVER_ERROR,
    INVALID_FORMAT_ERROR,
    TIMEOUT_ERROR,
    UNKNOWN_ERROR,
} from '../constants'
import * as Errors from '../errors'

export const MESSAGES_DEFAULT_BY_INTANCE_ERROR = {
    EmptyError: 'No hay contenido disponible.',
    UnauthorizedError: 'Acceso no autorizado.',
    ForbiddenError: 'El acceso está prohibido.',
    NotFoundError: 'Recurso no encontrado.',
    BookingInThePastError: 'La fecha de reserva está en el pasado.',
    AlreadyBookedError: 'Esto ya ha sido reservado.',
    NumberPlateNotInWhiteListError: 'La matrícula no está en la lista blanca.',
    MaxCouponsExceededError: 'Se ha superado el número máximo de cupones.',
    CreditCardInvalidError: 'Detalles de la tarjeta de crédito no válidos.',
    ServerError: 'Ocurrió un error en el servidor.',
    InvalidFormatError: 'Formato de respuesta no válido.',
    UnprocessableEntityError: 'No se pudo procesar la solicitud.',
    UnavailableParking: 'Parking no disponible',
    Error: 'Error genérico',
    TimeoutError: 'Error por timeout',
    unknownError: 'Ocurrió un error desconocido.',
}

export function buildCustomErrorMessageByErrorType ({ errors, messages = MESSAGES_DEFAULT_BY_INTANCE_ERROR }) {
    if (!errors) return errors
    if (Array.isArray(errors)) {
        return errors.map(err => {
            return {message: messages[err.name] || messages.unknownError}
        })
    }
    return {message: messages[errors?.name] || messages.unknownError}
}

export function buildClientError (error) {
    if (error instanceof Errors.UnauthorizedError) {
        return { name: UNAUTHORIZED_ERROR }
    }
    if (error instanceof Errors.ForbiddenError) {
        return { name: FORBIDDEN_ERROR }
    }
    if (error instanceof Errors.NotFoundError) {
        return { name: NOT_FOUND_ERROR }
    }
    if (error instanceof Errors.UnprocessableEntityError) {
        return { name: UNPROCESSABLE_ENTITY_ERROR }
    }
    if (error instanceof Errors.ServerError) {
        return { name: SERVER_ERROR }
    }
    if (error instanceof Errors.InvalidFormatError) {
        return { name: INVALID_FORMAT_ERROR }
    }
    if (error instanceof Errors.TimeoutError) {
        return { name: TIMEOUT_ERROR }
    }
    return { name: UNKNOWN_ERROR }
}

export function buildRegistrationError (errors) {
    if (errors.length) {
        return errors
    }

    return [{ message: errors.message }]
}

export function buildError ([firstError = {}]) {
    return {
        message: firstError.message,
    }
}

export function buildFieldError ({ field, message }) {
    return {
        field,
        message,
    }
}

export function buildBookingErrorData (error) {
    const data = { ...error.response }

    return {
        ...data,
        clientSecret: data?.client_secret,
        promoCode: data?.promotional_code,
        minPrice: data?.code_min_amount,
    }
}

export function buildVehicleError (error) {
    return {
        ...buildClientError(error),
        status: error.status,
    }
}

export function buildBookingAvailabilityError (errors) {
    const errorCustomMessage = buildCustomErrorMessageByErrorType({errors})

    if (errorCustomMessage.length) {
        return errors.map(buildFieldError)
    }

    return [
        {
            message: errorCustomMessage.message,
        },
    ]
}

export function buildLoginError (errors) {
    if (errors.length) {
        return buildFieldError(errors[0])
    }

    return {
        status: errors.status,
        message: errors.message,
    }
}

export function buildDeleteVehicleError (error) {
    return {
        status: error.status,
        message: error.message,
    }
}
