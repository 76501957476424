import * as Errors from '../client/errors'

export const UnauthorizedError = Errors.UnauthorizedError
export const ForbiddenError = Errors.ForbiddenError
export const NotFoundError = Errors.NotFoundError
export const ServerError = Errors.ServerError
export const InvalidFormatError = Errors.InvalidFormatError
export const TimeoutError = Errors.TimeoutError
export const UnprocessableEntityError = Errors.UnprocessableEntityError
export const AlreadyBookedError = Errors.AlreadyBookedError
export const NumberPlateNotInWhiteListError = Errors.NumberPlateNotInWhiteListError
export const MaxCouponsExceededError = Errors.MaxCouponsExceededError
export const CreditCardInvalidError = Errors.CreditCardInvalidError
export const EmptyError = Errors.EmptyError
export const BookingInThePastError = Errors.BookingInThePastError
export const UnavailableParking = Errors.UnavailableParking
