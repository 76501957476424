import Routes from '../../routes/routes'
import { buildVehicleInspectionBooking } from '../converters'
import { buildGenericError, buildGetApi } from '../helpers'
import buildOptions from '../buildApiOptions'
import * as Errors from '../../client/errors'
import messages from '../messages'

export default buildGetApi({
    route: Routes.apiRequestVehicleInspectionBooking,
    buildResult: buildVehicleInspectionBooking,
    mapParams: ({ bookingId }) => ({ bookingId }),
    buildOptions,
    buildError: (error) => {
        if (error instanceof Errors.NotFoundError) {
            return { message: messages.itvBookingNotFound }
        }
        buildGenericError(error)
    },
})
